import React from "react";
import { NavLink } from "react-router-dom";
import config from "../../services/config";
// import ContactForm from "../forms/contactForm/ContactForm";
import "./footer.css";

import policy from "../../images/policy.pdf";
import oferta from "../../images/oferta.pdf";

const Footer = () => (
  <footer className="footer">
    <div className="footer-overlay">
      <div className="footer-content">
        <div className="footer-content-block">
          <p className="footer-content-block-title">Єдина школа</p>
          <NavLink
            className="footer-content-block-item discr__letter-spacing"
            to="/schoolRegistration"
          >
            Зареєструвати заклад
          </NavLink>
          <NavLink className="footer-content-block-item" to="/teacher">
            Для вчителів
          </NavLink>
          <NavLink className="footer-content-block-item" to="/students">
            Для учнів
          </NavLink>
          <NavLink className="footer-content-block-item" to="/parents">
            Для батьків
          </NavLink>
          <NavLink className="footer-content-block-item" to="/about">
            Чому Єдина Школа?
          </NavLink>
          <NavLink className="footer-content-block-item" to="/news">
            Новини
          </NavLink>
          <NavLink className="footer-content-block-item" to="/links">
            Корисні посилання
          </NavLink>
          {/* <NavLink className="footer-content-block-item" to="/certificate">
            Сертифікати
          </NavLink> */}
        </div>
        <div className="footer-content-block">
          <p className="footer-content-block-title">Контакти</p>
          <div className="footer-content-block-item-overlay">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.9999 15.9201V18.9201C21.0011 19.1986 20.944 19.4743 20.8324 19.7294C20.7209 19.9846 20.5572 20.2137 20.352 20.402C20.1468 20.5902 19.9045 20.7336 19.6407 20.8228C19.3769 20.912 19.0973 20.9452 18.8199 20.9201C15.7428 20.5857 12.7869 19.5342 10.1899 17.8501C7.77376 16.3148 5.72527 14.2663 4.18993 11.8501C2.49991 9.2413 1.44818 6.27109 1.11993 3.1801C1.09494 2.90356 1.12781 2.62486 1.21643 2.36172C1.30506 2.09859 1.4475 1.85679 1.6347 1.65172C1.82189 1.44665 2.04974 1.28281 2.30372 1.17062C2.55771 1.05843 2.83227 1.00036 3.10993 1.0001H6.10993C6.59524 0.995321 7.06572 1.16718 7.43369 1.48363C7.80166 1.80008 8.04201 2.23954 8.10993 2.7201C8.23656 3.68016 8.47138 4.62282 8.80993 5.5301C8.94448 5.88802 8.9736 6.27701 8.89384 6.65098C8.81408 7.02494 8.6288 7.36821 8.35993 7.6401L7.08993 8.9101C8.51349 11.4136 10.5864 13.4865 13.0899 14.9101L14.3599 13.6401C14.6318 13.3712 14.9751 13.1859 15.3491 13.1062C15.723 13.0264 16.112 13.0556 16.4699 13.1901C17.3772 13.5286 18.3199 13.7635 19.2799 13.8901C19.7657 13.9586 20.2093 14.2033 20.5265 14.5776C20.8436 14.9519 21.0121 15.4297 20.9999 15.9201Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {/* <p className="footer-content-block-item">+380730110111</p> */}
            <a className="footer-content-block-item" href="tel:+380730110111">
              +380730110111
            </a>
          </div>
          <div className="footer-content-block-item-overlay">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22 6L12 13L2 6"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="footer-content-block-item">support@eschool-ua.com</p>
          </div>
          <div className="footer-content-block-item-overlay">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 22C17.5229 22 22 17.5229 22 12C22 6.47715 17.5229 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5229 6.47715 22 12 22Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 6V12L16 14"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="footer-content-block-item">
              Час роботи: пн-пт з 08:00 до 20:00
            </p>
          </div>
        </div>
        <div className="footer-content-block">
          <p className="footer-content-block-title">Соцмережі</p>
          <div className="footer-content-block-item-overlay">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.6177 23.5H16.712V15.1175H19.7183H20.1952L20.2177 14.6412L20.3774 11.2632L20.4022 10.7395H19.878H16.712V8.77279C16.712 8.30865 16.7695 8.09531 16.8555 7.98553C16.9163 7.90791 17.0768 7.78028 17.625 7.78028C18.1862 7.78028 18.7242 7.78271 19.122 7.78514C19.3209 7.78636 19.4846 7.78757 19.5986 7.78849L19.7304 7.78959L19.7648 7.78989L19.7736 7.78998L19.7758 7.79L19.7764 7.79L19.7765 7.79L19.7765 7.79L20.2687 7.79465L20.2811 7.30262L20.3606 4.14811L20.372 3.69768L19.9251 3.63967L19.8608 4.13551L19.925 3.63966L19.9249 3.63965L19.9246 3.63961L19.9237 3.63949L19.9208 3.63912L19.9107 3.63785L19.8734 3.63334C19.8412 3.62952 19.7943 3.62416 19.7341 3.61779C19.6137 3.60503 19.4399 3.58818 19.223 3.57138C18.7894 3.5378 18.1817 3.50426 17.4825 3.50426C15.688 3.50426 14.3385 4.06033 13.4379 4.97847C12.5414 5.89236 12.1398 7.11399 12.1398 8.35351V10.7395H10.1588H9.65877V11.2395V14.6175V15.1175H10.1588H12.1398V23.5H4.38226C2.23082 23.5 0.5 21.7692 0.5 19.6178V4.38226C0.5 2.23545 2.22338 0.507449 4.36838 0.500024H4.38223H19.6177C21.7692 0.500024 23.5 2.23085 23.5 4.38228V19.6178C23.5 21.7692 21.7692 23.5 19.6177 23.5Z"
                stroke="white"
              />
            </svg>
            <a
              className="footer-content-block-item"
              href="https://www.facebook.com/%D0%84%D0%B4%D0%B8%D0%BD%D0%B0-%D0%A8%D0%BA%D0%BE%D0%BB%D0%B0-100779398321404/ "
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </div>
          <div className="footer-content-block-item-overlay">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 1H4C2.34315 1 1 2.34315 1 4V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V4C23 2.34315 21.6569 1 20 1ZM4 0C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0H4Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 7H8C6.34315 7 5 8.34315 5 10V14C5 15.6569 6.34315 17 8 17H16C17.6569 17 19 15.6569 19 14V10C19 8.34315 17.6569 7 16 7ZM8 6C5.79086 6 4 7.79086 4 10V14C4 16.2091 5.79086 18 8 18H16C18.2091 18 20 16.2091 20 14V10C20 7.79086 18.2091 6 16 6H8Z"
                fill="white"
              />
              <path
                d="M13.85 11.9134C13.9167 11.9519 13.9167 12.0481 13.85 12.0866L11.15 13.6454C11.0833 13.6839 11 13.6358 11 13.5588V10.4412C11 10.3642 11.0833 10.3161 11.15 10.3546L13.85 11.9134Z"
                fill="white"
              />
            </svg>
            <a
              className="footer-content-block-item"
              href="https://www.youtube.com/channel/UCjBneVVfegWFgOVR4f84Elg "
              target="_blank"
              rel="noopener noreferrer"
            >
              YouTube
            </a>
          </div>
          <div className="footer-content-block-item-overlay">
            <div className="footer-content-block-item-svg footer-svg-instagram"></div>
            <a
              className="footer-content-block-item"
              href="https://www.instagram.com/eschool_ua/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          </div>
          <div className="footer-content-block-item-overlay">
            <div className="footer-content-block-item-svg footer-svg-viber"></div>
            <a
              className="footer-content-block-item"
              href="https://bit.ly/2ZTTZeo"
              target="_blank"
              rel="noopener noreferrer"
            >
              Viber
            </a>
          </div>
          <div className="footer-content-block-item-overlay">
            <div className="footer-content-block-item-svg footer-svg-telegram"></div>
            <a
              className="footer-content-block-item"
              href="https://t.me/eschool_ua"
              target="_blank"
              rel="noopener noreferrer"
            >
              Telegram
            </a>
          </div>
        </div>
        <div className="footer-content-block">
          <p className="footer-content-block-title">FAQ</p>
          <NavLink className="footer-content-block-item" to="/faq/teachers">
            Для вчителів
          </NavLink>
          <NavLink className="footer-content-block-item" to="/faq/parents">
            Для батьків
          </NavLink>
          <NavLink className="footer-content-block-item" to="/faq/students">
            Для учнів
          </NavLink>
        </div>
        <div className="footer-content-block">
          <a
            href="https://tatl.atlassian.net/servicedesk/customer/portal/"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-content-block__support-link"
          >
            Написати в службу підтримки
          </a>
        </div>
        <div className="footer-content-blockApp">
          <a
            className="footer-content-blockApp-link footer-content-blockApp-link-appStore"
            href="https://apps.apple.com/us/app/id1447875950"
            target="_blank"
            rel="noopener noreferrer"
          >
            App Store
          </a>
          <a
            className="footer-content-blockApp-link footer-content-blockApp-link-googlePlay"
            href="https://play.google.com/store/apps/details?id=com.tatl.technology.usu_fp_schedule"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Play
          </a>
        </div>
      </div>
      <div className="footer-links">
        <div className="footer-links-block">
          <div className="footer-links-block-item-rules">
            © Єдина Школа, 2018-2023. Всі права захищенні - {config.version}
          </div>
        </div>
        <div className="footer-links-block">
          <div className="footer-links-block-item">
            <a href={policy} target="_blank" rel="noopener noreferrer">
              Політика конфіденційності
            </a>
          </div>
          <div className="footer-links-block-item">
            <a href={oferta} target="_blank" rel="noopener noreferrer">
              Угода користувача
            </a>
          </div>
          <div className="footer-links-block-item">
            <a
              href="https://www.tatlgroup.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              by TATL Technology
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
