import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  openMenuAuth,
  closeMenuAuth,
  openMenuBurger,
  closeMenuBurger,
} from "../../../redux/actions";
import DropDownMenuTeacher from "./dropDownMenu/DropDownMenuTeacher.js";
import DropDownMenuParent from "./dropDownMenu/DropDownMenuParent";
import Prapor from "../../../images/Group 31231716.png";
import "./menu.css";

const Menu = (props) => {
  const handleCloseAuth = (e) => {
    if (
      !e.target.closest(".menu-authorization") &&
      !e.target.closest(".menu-navigation-item-login-js")
    ) {
      if (props.menuAuth) {
        props.closeMenuAuth();
      }
    }
  };

  const handleCloseBurger = (e) => {
    if (!e.target.closest(".submenu")) {
      if (props.menuBurger) {
        props.closeMenuBurger();
      }
    }
  };
  window.addEventListener("click", handleCloseAuth, true);
  window.addEventListener("click", handleCloseBurger, true);
  return (
    <nav className="menu">
      <ul className="menu-navigation">
        <li className="menu-navigation-item">
          <NavLink
            className="menu-navigation-item-link discr__letter-spacing"
            to="/schoolRegistration"
          >
            Зареєструвати заклад
          </NavLink>
        </li>
        <li className="menu-navigation-item">
          {/* <NavLink className="menu-navigation-item-link" to="/teacher" activeClassName="navlink-selected">Для вчителів</NavLink> */}
          <DropDownMenuTeacher />
        </li>
        <li className="menu-navigation-item">
          <NavLink
            className="menu-navigation-item-link"
            to="/students"
            activeClassName="navlink-selected"
          >
            Для учнів
          </NavLink>
        </li>
        <li className="menu-navigation-item">
          {/* <NavLink
            className="menu-navigation-item-link"
            to="/parents"
            activeClassName="navlink-selected"
          >
            Для батьків
          </NavLink> */}
          <DropDownMenuParent />
        </li>
        <li className="menu-navigation-item">
          <NavLink
            className="menu-navigation-item-link"
            to="/about"
            activeClassName="navlink-selected"
          >
            Чому Єдина Школа?
          </NavLink>
        </li>
        <li className="menu-navigation-item">
          <NavLink
            className="menu-navigation-item-link"
            to="/news"
            activeClassName="navlink-selected"
          >
            Новини
          </NavLink>
        </li>
        <li className="menu-navigation-item">
          <NavLink
            className="menu-navigation-item-link"
            to="/links"
            activeClassName="navlink-selected"
          >
            Корисні посиланння
          </NavLink>
        </li>
        <li id="user" className="menu-navigation-item">
          <NavLink
            className="menu-navigation-item-link"
            to="/contacts"
            activeClassName="navlink-selected"
          >
            Контакти
          </NavLink>
        </li>
        {/* <li id="login" className="menu-navigation-item menu-navigation-item-login-js" >
                    <NavLink className="menu-navigation-item-link" to="#" onClick={() => props.openMenuAuth()}>Особистий кабінет</NavLink>
                </li> */}
      </ul>
      <div id="user" className="menu-navigation-item absolute_icon">
        <NavLink className="menu-navigation-item-link" to="/donate">
          <div className="absolute_icon_textConteiner">
              <picture>
                <source srcSet={Prapor} />
                <img src={Prapor} alt="Laptop" width="auto" height={53}/>
              </picture>
          </div>
        </NavLink>
      </div>
      <button
        className="menu-burger"
        onClick={() => props.openMenuBurger()}
      ></button>
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    menuAuth: state.openMenuAuth,
    menuBurger: state.openMenuBurger,
  };
};

const mapDispatchToProps = {
  openMenuAuth,
  closeMenuAuth,
  openMenuBurger,
  closeMenuBurger,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
